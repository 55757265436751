/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-16 16:16:37
 * @Module: 关注列表
 */
 <template>
  <div class="followedList-box"
       v-loading="loading">
    <div class="followedList-list">
      <item v-for="(item,index) in list"
            :key="item.user_id"
            @on-click="(type)=>onClick(type,index,item)"
            :data="item" />
    </div>
    <div class="pagination"
         v-show="pagination.total>pagination.pageSize">
      <el-pagination layout="prev, pager, next"
                     style="margin:0 auto;"
                     hide-on-single-page
                     :current-page="pagination.current_page"
                     @current-change="page=>_get_list(page)"
                     :page-size="pagination.pageSize"
                     :total="pagination.total">
      </el-pagination>
    </div>
    <no-data v-show="!pagination.total" />
  </div>
</template>
 <script>
import followemixins from "./followemixins"
export default {
  components: {},
  mixins: [followemixins],
  data () {
    return {
    };
  },
  mounted () { },
  methods: {
    async _get_list (page = 1) {
      this.loading = true
      const { status, info, data, meta } = await this.$api.followedListApi({ page, uuid: this.uuid })
      this.loading = false
      if (status == 200) {
        this.list = data
        this.pagination.total = meta.pagination.total
        this.pagination.pageSize = meta.pagination.per_page
        this.pagination.current_page = meta.pagination.current_page
      } else {
        this.$message.error(info)
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
.followedList-box {
  background: #fff;
  background: #fff;
}
.pagination {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>