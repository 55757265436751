/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-16 15:08:44
 * @Module: 关注和粉丝列表
 */
 <template>
  <div>
    <div class="followe-nav">
      <div v-for="item in navList"
           :key="item.key"
           class="hover"
           @click="active=item.key">
        {{item.title}}
        <div class="active"
             v-show="active==item.key"></div>
      </div>
    </div>
    <followed-list v-show="active=='followed'" />
    <follower-list v-show="active=='follower'" />
  </div>
</template>
 <script>
import followedList from "./followedList"
import followerList from "./followerList"
export default {
  components: {
    followedList,
    followerList
  },
  data () {
    return {
      navList: [
        {
          title: '关注',
          key: "followed"
        },
        {
          title: '粉丝',
          key: "follower"
        }
      ],
      active: "followed"
    };
  },
  mounted () {
  },
  methods: {},
  watch: {
    "$route.query.type": {
      handler: function (val) {
        if (val)
          this.active = val
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
};
 </script>
 <style lang='scss' scoped>
.followe-nav {
  display: flex;
  line-height: 50px;
  background: #fff;
  padding-left: 30px;
  border-bottom: solid 1px #eee;
  & > div {
    margin-right: 40px;
  }
  .active {
    width: 30px;
    height: 3px;
    background: #df2a29;
    border-radius: 2px;
  }
}
</style>