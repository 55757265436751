import item from './item.vue'
import noData from "@/components/noData"
export default {
	components: { item, noData },
	data () {
		return {
			uuid: null,
			loading: false,
			pagination: {
				total: 0,
				pageSize: 20,
				page: null,
				current_page: 0
			},
			list: []
		};
	},
	mounted () {
		this.uuid = this.$route.query.uuid
		this._get_list()
	},
	methods: {
		onClick (type, index, { uuid }) {
			const has_followed = this.list[index].has_followed
			if (type == 'followe')
				this.list[index].has_followed = has_followed ? 0 : 1
			if (has_followed)
				this._get_unfollowApi(uuid)
			else
				this._get_follow(uuid)
		},
		// 关注
		async _get_follow (user_id) {
			const { status, info } = await this.$api.followApi({ user_id })
			if (status == 200) {
				this.$message.success(info)
			} else {
				this.$message.error(info)
			}
		},
		// 取消关注
		async _get_unfollowApi (user_id) {
			const { status, info } = await this.$api.unfollowApi({ user_id })
			if (status == 200) {
				this.$message.success(info)
			} else {
				this.$message.error(info)
			}
		},
	},
};