/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-16 17:29:56
 * @Module: 关注列表item
 */
 <template>
  <div class="item" @click="goInfo(data.uuid)">
    <el-avatar
      :size="54"
      class="item-l"
      :src="data.avatar"
      
    />
    <div class="item-c">
      <div>{{ data.nick_name }}</div>
      <div class="ellipsis">{{ data.description }}</div>
    </div>
    <div
      class="item-r hover"
      v-show="data.has_followed"
      @click="$emit('on-click', 'followe')"
    >
      已关注
    </div>
    <div
      class="item-r-ac hover"
      v-show="!data.has_followed"
      @click="$emit('on-click', 'followe')"
    >
      +关注
    </div>
  </div>
</template>
 <script>
export default {
  props: {
    data: {
      style: Object,
      default: () => ({})
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goInfo(id) {
      console.log(id)
        const { href } = this.$router.resolve({
           path: "/homepage/user/personal",
           query:{
             uuid:id
           }
          });
          window.open(href, "_blank"); //打开新的窗口
    }
  }
};
</script>
 <style lang='scss' scoped>
.item {
  height: 84px;
  // background: #f66;
  display: flex;
  align-items: center;
  margin: 0 30px;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  &-c {
    flex: 1;
    margin: 0 16px;
    & :first-child {
      font-size: 18px;
      color: #333;
    }
    & :last-child {
      font-size: 14px;
      color: #999;
      margin-top: 6px;
    }
  }
  &-r {
    width: 80px;
    line-height: 30px;
    background: #f7f7f7;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    color: #999999;
  }
  &-r-ac {
    width: 80px;
    line-height: 30px;
    background: #df2a29;
    border-radius: 2px;
    color: #fff;
    text-align: center;
  }
}
</style>